import React from 'react';
import './MadeWithSection.css';

const Footer = () => {
    // Array of image paths to brand logos
    const toolLogos = [
        'GoogleBlack.png', 
        'ExpoBlack.png', 
        'nodeJSBlack.png', 
        'githubBlack.png',
        'AndroidBlack.png',
        'Apple.png',
        'HTML.png',
        'VSCode.png',


    ];

    return (
        <section id="madeWith" className="madeWithSection">
            <div className="logos-container">
                {toolLogos.map((logo, index) => (
                    <img key={index} src={logo} alt={`Logo ${index + 1}`} className="logo-image" />
                ))}
            </div>
           
        </section>
    );
};

export default Footer;
