import React, { useState } from 'react';
import { db } from '../../firebase';
import { ref, push, query, orderByChild, equalTo, get, set } from "firebase/database";

const EmailEntryContainer = () => {
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [pattern, setPattern] = useState('');
    const [otherPattern, setOtherPattern] = useState(''); // State to hold the "other" pattern text
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePatternChange = (event) => {
        setPattern(event.target.value);
        if (event.target.value !== 'Other') {
            setOtherPattern(''); // Clear "Other" text if other option is selected
        }
    };

    const handleOtherPatternChange = (event) => {
        setOtherPattern(event.target.value);
    };

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!isValidEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        const emailRef = ref(db, 'SignedUpUsers');
        const emailQuery = query(emailRef, orderByChild("email"), equalTo(email));
        const snapshot = await get(emailQuery);

        if (snapshot.exists() && !snapshot.empty) {
            setError('This email has already been registered.');
            return;
        }

        const now = new Date();
        const timestamp = now.toISOString();
        const newUserRef = push(emailRef);
        const userPattern = pattern === 'Other' ? otherPattern : pattern; // Use otherPattern if "Other" is selected
        set(newUserRef, { email, pattern: userPattern, submittedAt: timestamp });

        setSubmitted(true);
    };

    return (
        !submitted ? (
            <form className="form-container" onSubmit={handleSubmit} >
                <div className="email-text-box">
                    <div className="content-wrapper-email">
                        <h3 className='signUpHereText'>Get notified on Knitties Release</h3>
                        <div className="email-text">Choose which knitting pattern you'd like to see next:</div>
                        <div className="pattern-options">
                            {/* Existing pattern options */}
                            <label className="radio-scarfs"><input type="radio" name="pattern" value="Scarfs" onChange={handlePatternChange} /> Scarfs</label>
                            <label className="radio-hats"><input type="radio" name="pattern" value="Hats" onChange={handlePatternChange} /> Hats</label>
                            <label className="radio-tote-bags"><input type="radio" name="pattern" value="Tote Bags" onChange={handlePatternChange} /> Tote Bags</label>
                            <label className="radio-socks"><input type="radio" name="pattern" value="Socks" onChange={handlePatternChange} /> Socks</label>
                            <label className="radio-blankets"><input type="radio" name="pattern" value="Blankets" onChange={handlePatternChange} /> Blankets</label>
                            <br/>
                            <label className="radio-cardigans"><input type="radio" name="pattern" value="Cardigans" onChange={handlePatternChange} /> Cardigans</label>
                            <label className="radio-shawls"><input type="radio" name="pattern" value="Shawls" onChange={handlePatternChange} /> Shawls</label>
                            <label className="radio-other"><input type="radio" name="pattern" value="Other" onChange={handlePatternChange} /> Other</label>
                            
                            <input
                                type="text"
                                value={otherPattern}
                                onChange={handleOtherPatternChange}
                                placeholder="Specify your pattern"
                                className="other-pattern-input"
                            />
                        </div>

                        <div className="email-info">Enter your email here, the first 100 will be given early access to the app: </div>
                        <input
                            type="email"
                            value={email}
                            onChange={handleInputChange}
                            placeholder="Your email address"
                            required
                            className="email-input"
                        />
                        {error && <p className="error-message">{error}</p>}
                        <button type="submit" className="subscribe-button">Submit</button>
                    </div>
                </div>
            </form>
        ) : (
            <div className="text-box">
                <p>Thank you for subscribing and for your vote!</p>
            </div>
        )
    );
};

export default EmailEntryContainer;
