// src/components/PrivacyPolicy/PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css'; // Ensure you have the CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: September 25, 2024</p>
      
      <h2>Introduction</h2>
      <p>
        Welcome to Knittie ("we," "us," or "our"). We are committed to protecting your personal data and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our knitting app ("App"), which allows users to design and customize knitting patterns.
      </p>
      
      <h2>Information We Collect</h2>
      <ol>
        <li>
          <strong>Personal Information</strong>
          <ul>
            <li><strong>Account Details:</strong> When you create an account, we may collect your name, email address, and password.</li>
            <li><strong>User Content:</strong> Any designs, patterns, or other content you create and save within the App.</li>
          </ul>
        </li>
        <li>
          <strong>Usage Data</strong>
          <ul>
            <li>Information about how you interact with the App, such as features used, time spent, and pages viewed.</li>
          </ul>
        </li>
        <li>
          <strong>Device Information</strong>
          <ul>
            <li>Details about the device you use, including model, operating system, unique device identifiers, and network information.</li>
          </ul>
        </li>
      </ol>
      
      <h2>How We Use Your Information</h2>
      <p>We use the collected data for various purposes:</p>
      <ul>
        <li>
          <strong>To Provide and Maintain Our Service</strong>
          <ul>
            <li>Facilitate account creation and authentication.</li>
            <li>Store and manage your custom knitting patterns.</li>
          </ul>
        </li>
        <li>
          <strong>To Improve Our Service</strong>
          <ul>
            <li>Analyze usage patterns to enhance user experience.</li>
            <li>Diagnose and fix technical issues.</li>
          </ul>
        </li>
        <li>
          <strong>To Communicate with You</strong>
          <ul>
            <li>Send updates, notifications, and important information regarding the App.</li>
            <li>Respond to your comments, questions, and requests.</li>
          </ul>
        </li>
      </ul>
      
      <h2>Data Storage and Security</h2>
      <p>
        Your data is securely stored using <strong>Firebase</strong>, a service provided by Google LLC. Firebase implements robust security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.
      </p>
      <ul>
        <li><strong>Firebase Authentication:</strong> Manages user authentication securely.</li>
        <li><strong>Firebase Firestore:</strong> Stores user-generated content and preferences.</li>
        <li><strong>Firebase Analytics:</strong> Helps us understand how users interact with the App.</li>
      </ul>
      <p>
        <strong>Firebase's Privacy Policy:</strong> <a href="https://firebase.google.com/support/privacy">https://firebase.google.com/support/privacy</a>
      </p>
      
      <h2>Third-Party Services</h2>
      <p>
        Our App may contain links to third-party websites or services not operated by us. We have no control over their content, privacy policies, or practices. We recommend reviewing the privacy policy of every third-party service you interact with.
      </p>
      
      <h2>Data Sharing and Disclosure</h2>
      <p>We do not sell or rent your personal data to third parties. We may share your information only in the following circumstances:</p>
      <ul>
        <li>
          <strong>Legal Obligations:</strong>
          <ul>
            <li>If required by law or in response to valid requests by public authorities.</li>
          </ul>
        </li>
        <li>
          <strong>Business Transfers:</strong>
          <ul>
            <li>In connection with any merger, sale of company assets, financing, or acquisition.</li>
          </ul>
        </li>
      </ul>
      
      <h2>Your Rights</h2>
      <p>You have the following rights regarding your personal data:</p>
      <ul>
        <li><strong>Access:</strong> Request a copy of the information we hold about you.</li>
        <li><strong>Correction:</strong> Ask us to correct any inaccuracies in your personal data.</li>
        <li><strong>Deletion:</strong> Request the deletion of your personal data.</li>
        <li><strong>Restriction:</strong> Limit the way we use your data.</li>
        <li><strong>Portability:</strong> Receive your data in a structured, commonly used format.</li>
      </ul>
      <p>
        To exercise these rights, please contact us at <strong>knittiecontact@gmail.com</strong>.
      </p>
      
      <h2>Data Retention</h2>
      <p>
        We retain your personal data only as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
      </p>
      
      <h2>Data Security</h2>
      <p>
        We employ industry-standard security measures to protect your information. However, no method of transmission over the internet or electronic storage is 100% secure.
      </p>
      
      <h2>International Data Transfers</h2>
      <p>
        Your information may be transferred to—and maintained on—servers located outside of your country, where data protection laws may differ. By using the App, you consent to such transfers.
      </p>
      
      <h2>Children's Privacy</h2>
      <p>
        Our App is not intended for individuals under the age of 13. We do not knowingly collect personal data from children under 13. If you become aware that a child has provided us with personal data, please contact us, and we will take steps to delete such information.
      </p>
      
      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy periodically. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.
      </p>
      
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at:
      </p>
      <p><strong>Email:</strong> knittiecontact@gmail.com</p>
    </div>
  );
};

export default PrivacyPolicy;
