// Import Firebase modules
import { initializeApp } from "firebase/app";
import { getDatabase, ref, push, set } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBP7EYDzoABGnAb3RctTxeDb5Ivyh67z8M",
    authDomain: "knittie-website.firebaseapp.com",
    projectId: "knittie-website",
    storageBucket: "knittie-website.appspot.com",
    messagingSenderId: "295647341735",
    appId: "1:295647341735:web:6c5c067587da805bcfbf82",
    measurementId: "G-QXEHCLH6XE"
  };

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export { db };
