// src/components/ContactSection.js
import React from 'react';
import ArtyFooter from '../ArtyFooter/ArtyFooter';

const ContactSection = () => {
    return (
        <section id="contact" className="section">
           
            <div className="content-wrapper">
                
                
                <div className="text-box">
                    <h2  className="ContactHeading">Feel Free To Contact Us!</h2>
                   
                   <div className='emailInfo'> <vb>knittiecontact@gmail.com</vb></div>
                    <ArtyFooter/>

                </div>
             
            </div>

            
        </section>
    );
};

export default ContactSection;
