import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './HeroSection.css'; // Import the CSS file

const HeroSection = () => {
    const [bgStyle, setBgStyle] = useState({
        background: 'rgba(255, 255, 255, 1)', // Starts with a white background
        transition: 'background 0.5s ease' // Smooth transition for the background change
    });

    const [speechText, setSpeechText] = useState("Welcome to Knittie!");

    const randomTexts = useMemo(() => [
        "Have a great day!",
        "Let us know what you want next!",
        "We hope your as excited as we are!",
        "Welcome back!",
        "Hellllloooooo!",
        "Stay warm with Knittie!",
        "Looking Good!",
        "Check out our insta!",
        "Slay!",
        "The way you scroll is impressive!",
    ], []);

    const [showPenguin, setShowPenguin] = useState(true); // State to control penguin visibility

    const handleScroll = useCallback(() => {
        const position = window.scrollY;
        const height = window.innerHeight;
        const triggerThreshold = height * 0.2; // Threshold to trigger animation

        let opacity = 1 - (position / height);
        opacity = Math.max(opacity, 0); // Ensure opacity does not go below 0

        setBgStyle({
            background: `rgba(255, 255, 255, ${opacity})`, // Fades to black
            transition: 'background 0.5s ease'
        });

        if (position < triggerThreshold && !showPenguin) {
            setShowPenguin(true);
            const randomIndex = Math.floor(Math.random() * randomTexts.length);
            setSpeechText(randomTexts[randomIndex]);
        } else if (position >= triggerThreshold && showPenguin) {
            setShowPenguin(false);
        }
    }, [showPenguin, randomTexts]);

    useEffect(() => {
        const debounce = (func, wait) => {
            let timeout;
            return function (...args) {
                const context = this;
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(context, args), wait);
            };
        };

        const debouncedHandleScroll = debounce(handleScroll, 50);

        window.addEventListener('scroll', debouncedHandleScroll);

        return () => {
            window.removeEventListener('scroll', debouncedHandleScroll);
        };
    }, [handleScroll]);

    return (
        <section id="home" className="hero" style={bgStyle}>
            <h1>Knittie</h1>
            <div style={{ position: 'absolute', height: 'calc(209vh)',marginLeft:'90vw' }}>
                <div 
                    className={`penguin-container ${showPenguin ? 'visible' : ''}`} 
                >
                    <img src="/penguinSpeech.png" alt="Penguin" className="penguin-image" />
                    <div className="speech-text">
                        {speechText}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
