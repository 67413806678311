import React from 'react';
import RandomShapes from './components/RandomShapes'; // Import the component

const ArtyFooter = () => {
    return (
        <div style={{margin:10,}}>
            
            <RandomShapes />

        </div>
    );
};

export default ArtyFooter;
