import React, { useState, useEffect } from 'react';
import './Header.css';

const Header = () => {
    const [navOpen, setNavOpen] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [headerOffset, setHeaderOffset] = useState(0);

    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            const currentScrollY = window.scrollY;
            const headerHeight = 90; // Assume header height is 60px
            const scrollThreshold = 50; // Threshold for closing the nav menu when scrolling

            if (Math.abs(currentScrollY - lastScrollY) > scrollThreshold && navOpen) {
                setNavOpen(false); // Close the nav if scrolling exceeds threshold and nav is open
            }

            if (currentScrollY > lastScrollY) {
                // Scrolling down
                const updatedOffset = Math.max(-headerHeight, headerOffset - (currentScrollY - lastScrollY));
                setHeaderOffset(updatedOffset);
            } else {
                // Scrolling up
                const updatedOffset = Math.min(0, headerOffset + (lastScrollY - currentScrollY));
                setHeaderOffset(updatedOffset);
            }
            setLastScrollY(currentScrollY); // Update lastScrollY to current scroll position
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }
    }, [lastScrollY, headerOffset, navOpen]); // Adding navOpen to dependency array

    const scrollToTop = (e) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setNavOpen(false);  // Close nav after scrolling
    };

    const handleNavClick = (e, customFunc) => {
        customFunc(e);
        setNavOpen(false);  // Always close the nav when an item is clicked
    };

    const toggleNav = () => {
        setNavOpen(!navOpen);
    };

    return (
        <header className="header" style={{ transform: `translateY(${headerOffset}px)` }}>
            <div className="header-content">
                <div className='penguinInstaContainer'>
                    <img className="headerPenguin" src="testPenguin.png" alt="Penguin" />
                </div>
                <h1 style={{marginLeft: -15}}>Knittie</h1>
                <button className="nav-toggle" onClick={toggleNav}>
                    <span className={`hamburger ${navOpen ? 'open' : ''}`}></span>
                </button>
            </div>
            <nav className={`headerButtons ${navOpen ? 'open' : ''}`}>
                <ul>
                    <li><a href="#home" onClick={(e) => handleNavClick(e, scrollToTop)}>Home</a></li>
                    <li><a href="#goals" onClick={(e) => handleNavClick(e, () => {}) }>Goals</a></li>
                    <li><a href="#products" onClick={(e) => handleNavClick(e, () => {}) }>Products</a></li>
                    <li><a href="#app" onClick={(e) => handleNavClick(e, () => {}) }>App</a></li>
                    <li><a href="#about" onClick={(e) => handleNavClick(e, () => {}) }>About</a></li>
                    <li><a href="#contact" onClick={(e) => handleNavClick(e, () => {}) }>Contact</a></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
