import React, { useState } from 'react';
import './LeadGenerationSection.css';

import ArtyFooter from '../ArtyFooter/ArtyFooter';
import EmailEntryContainer from '../EmailEntryContainer/EmailEntryContainer';


const LeadGenerationSection = () => {


    return (
        <section id="lead-generation" className="leadGenerationSection">
            
<div className='content-wrapper'>

<div style={{textAlign:'left',margin:10, justifyContent:'center'}}>
                     <h2 className='gridTitle'>Sign Up Here</h2>
                    
                     <ArtyFooter/>
                     </div>

<EmailEntryContainer/>
           
            </div>
        </section>
    );
};

export default LeadGenerationSection;
