import React from 'react';
import './ImageGrid.css';  // Ensure this is linked properly

const ImageGrid = () => {
    const images = [
        'jumper1.png', 
        'jumper2.png', 
        'jumper3.png',
        'jumper4.png', 
        'jumper5.png', 
        'jumper6.png', 
        'jumper7.png', 
        'jumper8.png',
        'jumper9.png', 
        'jumper10.png', 
        'jumper11.png',
        'jumper12.png', 
        'jumper13.png', 
        'jumper14.png', 
        'jumper15.png', 
        'jumper16.png',
    ];

    return (
        <div className='grid-container'>

            <div className="grid">
                {images.map((img, index) => (
                    <img key={index} src={img} alt={`Jumper ${index + 1}`} className="grid-item" />
                ))}
            </div>
        </div>
    );
};

export default ImageGrid;
