import React from 'react';
import ArtyFooter from '../ArtyFooter/ArtyFooter';

import ImageGrid from './components/ImageGrid';


const ExamplesSection = () => {
    

    return (
        <section id="products" className="section">
        
            <div className="content-wrapper">
    <div className='grid-page-container'>
            
            <ImageGrid/>

        <div className='page-text-container'>
              

                <div className='gridTitle'>Design Your Jumper</div>
                <br/>
               
                    <ArtyFooter/>

                    <div className='text-box'>
                    <p>Currently, we offer the ability to completely customise a simple drop-shoulder jumper. However, we aim to bring any and all kinds of knitting projects! You can even vote which one you want next at the bottom of this page.</p>
               
                    </div>
                    </div>


               </div>

            </div>
        </section>
    );
};

export default ExamplesSection;
