import React, { useState } from 'react';
import ArtyFooter from '../ArtyFooter/ArtyFooter';
import './AboutSection.css'

const AboutSection = () => {
    const [isActive, setIsActive] = useState(false);

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    return (
        <section id="about" className="section">
            <div className="content-wrapper">


            <div className='page-text-container'>
            <h2 className="gridTitle">About Us</h2>
            <ArtyFooter/>

<div className='text-box-left'>
                <div className="textBoxTitles">
                Ian
                </div>

                        <p>Hey, Im Ian, and dont worry im a bit older now! I'm the head of operations and lead programmer for Knittie. I've spent countless hours and evenings with a coffee and my laptop open working away to make Knittie the absolute best it can be for the knitting community. I'm a fanatical artist and deeply enjoy bringing ideas to life and thats why I'm so passionate about giving as much creative control to the knitting community as possible and its my job to make sure that happens. So chuck me an email with any of your suggestions and look forward to chatting with you!</p>

<br/>

                <div className="textBoxTitles">
                Kathy
                </div>

                        <p>Hi, I'm Kathy, a knitter since childhood. I love colour, texture  and the formation of fabric with every stitch. Just another row and then another and another and before you know it you are well on your way to creating something unique and personal…the perfect gift. I have the pleasure to work alongside my two sons,  to share my expertise, experience  and enthusiasm so I can join you in creating something special.
</p>

                        <br/>

                <div className="textBoxTitles">
                Emil
                </div>

                        <p>Hey there, I'm Emil, Ian's older brother and the resident data scientist at Knittie. When I'm not crunching patterns, you'll find me lost in the world of cinema or buried in a good book. My wardrobe? Let's just say I've never met a turtleneck I didn't like. As a data scientist, I bring an analytical edge to Knittie's creative flair, ensuring that every stitch is backed by solid insights. Got a knotty problem that needs unraveling? Drop me a line – I'm always up for a good data-driven discussion.</p>


                
                </div>
               
                </div>
               
                <div className="teamPhotoContainer" onClick={toggleActive}>
                    <img className={`teamPhoto ${isActive ? 'active' : ''}`} src="us.png" alt="Team Photo" />
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
