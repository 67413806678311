import React, { useState, useEffect } from 'react';
import './RandomShapes.css'

const RandomShapes = () => {
    const images = [
        { src: 'circle.png', alt: 'Circle' },
        { src: 'triangle.png', alt: 'Triangle' },
        { src: 'square.png', alt: 'Square' }
    ];

    const [shuffledImages, setShuffledImages] = useState([]);

    useEffect(() => {
        shuffleAndRotateImages();
    }, []);

    const shuffleAndRotateImages = () => {
        // Shuffle images using a modern version of the Fisher-Yates shuffle
        const shuffled = images
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);

        // Assign a random rotation angle to each image
        const rotated = shuffled.map(img => ({
            ...img,
            rotation: Math.floor(Math.random() * 360) // random rotation from 0 to 359 degrees
        }));

        setShuffledImages(rotated);
    };

    return (
        <div>
            {shuffledImages.map((img, index) => (
                <img
                    key={index}
                    className="shapefooter"
                    src={img.src}
                    alt={img.alt}
                    style={{ transform: `rotate(${img.rotation}deg)` }}
                />
            ))}
        </div>
    );
};

export default RandomShapes;
